import './outbox-search.component.scss';

import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef
} from '@angular/core';
import { Transition, StateService } from '@uirouter/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Account, Campaign, Outbox, User } from '@ui-resources-angular';
import {
  authUser,
  campaigns,
  colleagues,
  workflowAccounts,
  postTags
} from '../../../common-resolves';
import { KeyValueObject } from '../../../../common/utils';
import { Colleague, Tag } from '../../../../common/services/api';
import { CampaignModelService } from '../../../auth/marketing/index/campaign-model/campaign-model.service';
import { OutboxQueryFactoryService } from '../../../../common/services/outbox-query-factory/outbox-query-factory.service';
import {
  Filter,
  Filters,
  FiltersComponent
} from '../../../../common/components/filters';

@Component({
  selector: 'ssi-outbox-search',
  templateUrl: './outbox-search.component.html'
})
export class OutboxSearchComponent implements OnInit, OnDestroy {
  static resolve = [
    workflowAccounts,
    campaigns,
    colleagues,
    authUser,
    postTags
  ];

  @ViewChild('filtersRef') filtersComponent: FiltersComponent;

  @Input() workflowAccounts: Account[];
  @Input() campaigns: Campaign[];
  @Input() colleagues: Colleague[];
  @Input() authUser: User;
  @Input() postTags: Tag[];

  outboxQuery;
  selectedPosts: Outbox[] = [];
  quickSelectCheckboxChecked = false;
  initialPostsLoadCount = 25;
  hideTotals = false;

  filters: Filters;
  filtersVisible = false;
  activeFilters: Filter[] = [];
  savedQuery: KeyValueObject = {};

  queryOverrides;

  protected destroyed$ = new Subject<void>();

  constructor(
    protected state: StateService,
    protected transition: Transition,
    protected campaignModelService: CampaignModelService,
    protected outboxQueryFactory: OutboxQueryFactoryService
  ) {}

  ngOnInit() {
    const now = new Date();
    const lastMonth = new Date(now.setDate(now.getDate() - 30));

    // to prepopulate the filters
    this.savedQuery = this.state.params.query
      ? JSON.parse(this.state.params.query)
      : { start_date: lastMonth };
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  createQueryAndSearch(filters: Filters): void {
    this.filters = filters;
    const query = this.filtersComponent.toApiParams();
    query.with = ['boosted_post_counts', 'note_counts', 'interaction_totals']; // TODO: needed?

    // console.log('query: ', query);

    this.outboxQuery = this.outboxQueryFactory.create({
      allAccounts: this.workflowAccounts,
      campaigns: this.campaigns,
      authUser: this.authUser,
      postTags: this.postTags,
      savedQuery: JSON.parse(JSON.stringify(query)),
      onSearch: () => {
        this.state.go(
          this.state.current,
          { query: JSON.stringify(query) },
          { reload: false }
        );
        this.hideTotals = this.onlyYoutubeAccountsFiltered();
        // bit of logic to abide by youtubes terms regarding metrics
      }
    });
    // if (this.outboxQuery.apiQuery) {
    //   this.outboxQuery.apiQuery.campaign_id = [this.activeCampaignId];
    // }

    // OutboxQuery is internally modifying the passed query object - override everything with the query from the new filters (until we get rid of OutboxQuery)
    this.queryOverrides = JSON.parse(JSON.stringify(query));

    this.outboxQuery.search(
      0,
      this.initialPostsLoadCount,
      true,
      this.queryOverrides
    );
  }

  private _initialPostsSelected(): boolean {
    return (
      this.selectedPosts.length === this.initialPostsLoadCount &&
      this.selectedPosts.every((iPost) => {
        return this.outboxQuery.outboxPosts
          .slice(0, this.initialPostsLoadCount)
          .map((outboxPost) => outboxPost.id)
          .includes(iPost.id);
      })
    );
  }

  private onlyYoutubeAccountsFiltered() {
    const filteredAccounts = this.outboxQuery.apiQuery.accounts.map(
      (accountId) =>
        this.outboxQuery.allAccounts.find((account) => account.id === accountId)
    );
    const youtubeAccounts = filteredAccounts.filter(
      ({ account_type_id }) => account_type_id === '6'
    );

    return youtubeAccounts.length === this.outboxQuery.apiQuery.accounts.length;
  }

  deselectAllPosts() {
    this.selectedPosts = [];
    this.quickSelectCheckboxChecked = false;
  }

  getSelectedPostIds() {
    return this.selectedPosts.map((post) => post.id);
  }

  canBulkEditSelected() {
    return (
      this.selectedPosts.filter((post) => post.social_id || post.deleted_at)
        .length === 0
    );
  }

  onlyScheduledPostsSelected() {
    return (
      this.selectedPosts.filter((post) => post.send_at && !post.social_id)
        .length === 0
    );
  }

  togglePostSelected({ isSelected, post }) {
    if (isSelected && this.selectedPosts.length < this.initialPostsLoadCount) {
      this.selectedPosts.push(post);
    }
    if (!isSelected) {
      this.selectedPosts = this.selectedPosts.filter(
        (iPost) => iPost.id !== post.id
      );
    }
    this.quickSelectCheckboxChecked = this._initialPostsSelected();
  }

  quickSelectCheckboxToggle(checked) {
    if (!checked) {
      this.deselectAllPosts();
      return;
    }
    this.selectedPosts = this.outboxQuery.outboxPosts.slice(
      0,
      this.initialPostsLoadCount
    );
  }
}
